// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import store from '@/store/store'
import App from './App'
import router from './router'
import Vuelidate from 'vuelidate'
import VueTouchKeyboard from 'vue-touch-keyboard'
import "vue-touch-keyboard/dist/vue-touch-keyboard.css";

Vue.use(VueTouchKeyboard)
// todo
// cssVars()

Vue.use(BootstrapVue)
Vue.use(Vuelidate)

const isDev = process.env.NODE_ENV === "development"

Vue.prototype.$api = isDev ? 'http://localhost:61904' : 'http://labbackend.atilimyazilim.com' // http://192.168.1.3:3344 - http://sql.atilimyazilim.com:3344


/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  template: '<App/>',
  components: {
    App
  }
})
