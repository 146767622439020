import Vue from 'vue'
import Router from 'vue-router'

const isDev = process.env.NODE_ENV === "development"

Vue.prototype.$api = isDev ? 'http://localhost:58702' : 'http://sql.atilimyazilim.com:3232'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Dinamik Import Ediliyor Böylece Code Splitting Oluyor

const Giris = () => import('@/views/giris/giris')
const Cikis = () => import('@/views/giris/cikis')


Vue.use(Router)

let router = new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/anasayfa',
      name: 'Anasayfa',
      component: DefaultContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'anasayfa',
          name: '',
          component: () => import('@/views/Anasayfa')
        },
        {
          path: 'add-analysis-result',
          name: 'EMP Results',
          component: () => import('@/views/formlar/analysis-result')
        },
        {
          path: 'pending-approval',
          name: 'Pending Approval',
          component: () => import('@/views/formlar/pending-approvals')
        }
      ]
    },
    {
      path: '/',
      name: 'Login Operations',
      component: Giris,
      meta: {
        requiresAuth: false
      },
      children: [
        {
          path: 'giris',
          name: 'Login',
          component: Giris
        }
      ]
    },
    {
      path: '/',
      name: 'Logout Operations',
      component: Cikis,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'cikis',
          name: 'Çıkış',
          component: Cikis
        }
      ]
    }
  ]
})

import { User, userInfo } from '@/shared/http';

router.beforeEach((to, from, next) => {
  let currentAdmin = localStorage.currentAdmin ? JSON.parse(localStorage.getItem('currentAdmin')) : undefined;

	let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
  if (requiresAuth && !currentAdmin) {
		next('/giris')
	} else if (!requiresAuth && currentAdmin) {
		next('/anasayfa')
	} else {
    const data = userInfo()
    User.auth = new Boolean(data)
    User.PersonelID = data.ID
		next()
	}
})

export default router