import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		satirlar: [],
		un: {
			satirlar: []
		},
		yag: {
			satirlar: []
		},
		sponc: [],
		doughHamur: []
	},

	getters: {
		satirlar: state => state.satirlar,
		unSatirlar: state => state.un.satirlar,
		yagSatirlar: state => state.yag.satirlar,
		sponcSatirlar: state => state.sponc,
		doughHamurSatirlar: state => state.doughHamur
	},

	mutations: {
		FILL_SATIRLAR(state, satirlar) {
			state.satirlar = satirlar
		},
		FILL_UN_SATIRLAR(state, satirlar) {
			state.un.satirlar = satirlar
		},
		FILL_YAG_SATIRLAR(state, satirlar) {
			state.yag.satirlar = satirlar
		},
		FILL_SPONC(state, satirlar) {
			state.sponc = satirlar
		},
		FILL_DOUGH_HAMUR(state, satirlar) {
			state.doughHamur = satirlar
		}
	},

	actions: {
		fillSatirlar(ctx, satirlar) {
			ctx.commit('FILL_SATIRLAR', satirlar)
		},
		fillUnSatirlar(ctx, satirlar) {
			ctx.commit('FILL_UN_SATIRLAR', satirlar)
		},
		fillYagSatirlar(ctx, satirlar) {
			ctx.commit('FILL_YAG_SATIRLAR', satirlar)
		},
		fillSponc(ctx, satirlar) {
			ctx.commit('FILL_SPONC', satirlar)
		},
		fillDoughHamur(ctx, satirlar) {
			ctx.commit('FILL_DOUGH_HAMUR', satirlar)
		}
	}
})