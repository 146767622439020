

export const headers = {
	'Content-Type': 'application/json'
}

export const userInfo = () => {
	return localStorage.currentAdmin ? JSON.parse(localStorage.currentAdmin) : 0
}

export const User = {
	auth: new Boolean(userInfo()),
	ID: userInfo().ID,
	BayiID: userInfo().BayiID
}

export const CheckAuth = ($router, pageName) => {
	const page = (localStorage.pages) ? JSON.parse(localStorage.pages) : null

	if(!page) {
		$router.push({ path: '/' })
	} else if(!page[pageName]) {
		$router.push({ path: '/' })
	}
}

export const DepartmentControl = (Api, UserID, DepartmentID) => {
	return fetch(`${Api}/api/generic/bolum-kontrolu/${UserID}/${DepartmentID}`, {
		method: 'GET',
		headers: headers,
	}).then(resp => resp.json())
} 